// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---node-modules-gatsby-plugin-offline-app-shell-js": () => import("/opt/build/repo/node_modules/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---node-modules-gatsby-plugin-offline-app-shell-js" */),
  "component---src-templates-post-post-jsx": () => import("/opt/build/repo/src/templates/Post/Post.jsx" /* webpackChunkName: "component---src-templates-post-post-jsx" */),
  "component---src-pages-404-js": () => import("/opt/build/repo/src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-blog-js": () => import("/opt/build/repo/src/pages/blog.js" /* webpackChunkName: "component---src-pages-blog-js" */),
  "component---src-pages-casa-novo-sancti-petri-js": () => import("/opt/build/repo/src/pages/casa-novo-sancti-petri.js" /* webpackChunkName: "component---src-pages-casa-novo-sancti-petri-js" */),
  "component---src-pages-casa-playa-conil-js": () => import("/opt/build/repo/src/pages/casa-playa-conil.js" /* webpackChunkName: "component---src-pages-casa-playa-conil-js" */),
  "component---src-pages-datenschutzerklaerung-js": () => import("/opt/build/repo/src/pages/datenschutzerklaerung.js" /* webpackChunkName: "component---src-pages-datenschutzerklaerung-js" */),
  "component---src-pages-impressum-js": () => import("/opt/build/repo/src/pages/impressum.js" /* webpackChunkName: "component---src-pages-impressum-js" */),
  "component---src-pages-index-js": () => import("/opt/build/repo/src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-meine-kleine-finca-js": () => import("/opt/build/repo/src/pages/meine-kleine-finca.js" /* webpackChunkName: "component---src-pages-meine-kleine-finca-js" */),
  "component---src-pages-ueber-mich-js": () => import("/opt/build/repo/src/pages/ueber-mich.js" /* webpackChunkName: "component---src-pages-ueber-mich-js" */),
  "component---src-pages-ueberwintern-js": () => import("/opt/build/repo/src/pages/ueberwintern.js" /* webpackChunkName: "component---src-pages-ueberwintern-js" */)
}

